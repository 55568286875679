import React, { useEffect, useState } from 'react';
import {
    FulfillmentProductConfiguration,
    PRODUCT_CATEGORIES,
    PRODUCT_CATEGORY,
    PRODUCT_GROUP_TYPE,
    PRODUCT_GROUP_TYPES,
} from '@schuettflix/interfaces';
import { useTranslation } from 'react-i18next';
import { useOrganizationSuspenseQuery } from '@/clients/organization/useOrganization.ts';
import {
    getProductSectionName,
    useFulfillmentContext,
    OrderFulfillmentProduct,
} from '@/modules/fulfillment/context/FulfillmentContext.tsx';
import { FulfillmentCustomRequestProduct } from '@/CustomRequestProductChannel/modules/fulfillment/FulfillmentCustomRequestProduct.tsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFulfillmentQuote } from '@/CustomRequestProductChannel/clients/CustomRequestClient.ts';
import { useSetSectionsValidity } from '@/modules/fulfillment/hooks/useSectionsValidity.ts';
import { Button, Modal, ModalTrigger } from '@schuettflix/planum-react';
import { useFranco } from '@/modules/fulfillment/hooks/useFranco.ts';
import { FulfillmentCustomRequestProductForOrderedProduct } from '@/CustomRequestProductChannel/modules/fulfillment/FulfillmentCustomRequestProductForOrderedProduct.tsx';
import { AddProductButton } from '@/shared/components/AddProductButton/AddProductButton.tsx';
import { ProductGroupHeader } from '@/shared/components/ProductGroupHeader/ProductGroupHeader.tsx';
import {
    deleteFulfillmentProduct,
    patchFulfillmentProductConfiguration,
    postFulfillmentProduct,
} from '@/clients/fulfillment';
import { getFulfillmentProductGroupsByOrderIdQueryKey } from '@/clients/fulfillment/useFulfillmentProductGroup.ts';
import { ToggleSwitch } from '@schuettflix/react-components';
import { useFeatureFlag } from '@/tools/featureFlags/useFeatureFlag';
import { ProductSelectionIndividualLineItemListing } from './ProductSelectionIndividualLineItemListing';
import { useLatest } from 'react-use';
import { useEmitter } from '@/shared/hooks/useEmitter';
import { asyncDelay } from '@/shared/utils/async';
import { MaybePromise } from '@/shared/types/MaybePromise';

interface ProductSelectionSectionProps {
    disabled?: boolean;
}

export const ProductSelectionSection: React.FC<ProductSelectionSectionProps> = ({ disabled }) => {
    const { order, productGroup, orderFulfillmentProducts, fulfillmentProducts, setCanSubmit } =
        useFulfillmentContext();
    const { data: orderingOrganization } = useOrganizationSuspenseQuery(order?.customerInfo?.organizationId);

    const isFrancoCase = productGroup.type == PRODUCT_GROUP_TYPES.FRANCO;
    const isMerchantCase = productGroup.type == PRODUCT_GROUP_TYPES.MERCHANT;

    const { t } = useTranslation();
    const [deleteModal, setDeleteModal] = useState<
        { open: false } | { open: true; quoteId?: string | null; index?: number | null; productId?: string | null }
    >({ open: false });
    const queryClient = useQueryClient();
    const setSectionsValidity = useSetSectionsValidity();

    const { mutateAsync: mutateAsyncDeleteFulfillmentProduct, isPending: deleteFulfillmentProductIsPending } =
        useMutation({
            mutationKey: ['delete-fulfillment-product'],
            mutationFn: deleteFulfillmentProduct,
            onSuccess: async () => {
                await queryClient.invalidateQueries({
                    queryKey: getFulfillmentProductGroupsByOrderIdQueryKey(productGroup.orderId),
                });
                setDeleteModal({ open: false });
            },
        });

    const { mutateAsync: mutateAsyncDeleteFulfillmentQuote, isPending: deleteFulfillmentQuoteIsPending } = useMutation({
        mutationKey: ['order-product-group', 'product-quote'],
        mutationFn: deleteFulfillmentQuote,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['quotes-by-order', productGroup.orderId] });
            void queryClient.invalidateQueries({
                queryKey: ['fulfillment-summary', productGroup.orderId],
            });
            setDeleteModal({ open: false });
        },
    });

    const { mutateAsync: mutateAsyncPostFulfillmentProduct } = useMutation({
        mutationKey: ['post-fulfilment-product'],
        mutationFn: postFulfillmentProduct,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: getFulfillmentProductGroupsByOrderIdQueryKey(productGroup.orderId),
            });
        },
    });

    const { mutateAsync: mutateAsyncPatchFulfillmentProductConfiguration } = useMutation({
        mutationKey: ['patch-fulfilment-product-configuration'],
        mutationFn: patchFulfillmentProductConfiguration,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: getFulfillmentProductGroupsByOrderIdQueryKey(productGroup.orderId),
            });
        },
    });

    const getFrancoProductPerCategory = (category: PRODUCT_CATEGORY) => {
        if (!isFrancoCase) {
            return;
        }

        return orderFulfillmentProducts.find(product => product.category === category);
    };

    const francoMaterialQuote = getFrancoProductPerCategory(PRODUCT_CATEGORIES.MATERIAL)?.quote;
    const francoTransportQuote = getFrancoProductPerCategory(PRODUCT_CATEGORIES.TRANSPORT)?.quote;

    const { francoFormErrors, onSalesPriceChange } = useFranco({
        initialMaterialSalesPrice: francoMaterialQuote?.salesPrice.toNumber(),
        initialTransportSalesPrice: francoTransportQuote?.salesPrice.toNumber(),
        initialFrancoFormErrors: {},
    });

    const francoMaterialProductFormErrors = {
        ...(francoFormErrors.materialSalesPrice && { salesPrice: francoFormErrors.materialSalesPrice }),
    };

    const francoTransportProductFormErrors = {
        ...(francoFormErrors.transportSalesPrice && { salesPrice: francoFormErrors.transportSalesPrice }),
    };

    const getHintByProduct = (product: OrderFulfillmentProduct) => {
        if (isFrancoCase) {
            return product.category === 'MATERIAL' || product.category === 'TRANSPORT'
                ? t('product.fulfillment.productSelection.productIsFrancoHint')
                : t('product.fulfillment.productSelection.productIsNotFrancoHint');
        }

        if (isMerchantCase && product.category === 'MATERIAL_TRANSPORT') {
            return t('product.fulfillment.productSelection.productIsNotFrancoHint');
        }
    };

    const getFrancoOverrideFormErrors = (productCategory: PRODUCT_CATEGORY) => {
        if (!isFrancoCase) {
            return;
        }

        if (productCategory === PRODUCT_CATEGORIES.MATERIAL) {
            return francoMaterialProductFormErrors;
        }

        if (productCategory === PRODUCT_CATEGORIES.TRANSPORT) {
            return francoTransportProductFormErrors;
        }
    };

    const handleFrancoSalesPriceChange = (salesPrice: number, productCategory: PRODUCT_CATEGORY) => {
        if (!isFrancoCase) {
            return;
        }

        if (productCategory === PRODUCT_CATEGORIES.MATERIAL) {
            onSalesPriceChange(salesPrice, 'materialSalesPrice');
        }

        if (productCategory === PRODUCT_CATEGORIES.TRANSPORT) {
            onSalesPriceChange(salesPrice, 'transportSalesPrice');
        }
    };

    const handleUpdateFulfillmentProductConfiguration = async (
        fulfillmentProductId: string,
        fulfillmentProductConfiguration: Partial<Omit<FulfillmentProductConfiguration, 'fulfillmentProductId'>> | null
    ) => {
        if (!fulfillmentProductConfiguration) return;

        await mutateAsyncPatchFulfillmentProductConfiguration({
            fulfillmentProductId,
            fulfillmentProductConfiguration,
        });
    };

    const handleDeleteProduct = async (productId: string) => {
        await mutateAsyncDeleteFulfillmentProduct({
            fulfillmentProductId: productId,
        });

        const invalidFulfillmentProducts = fulfillmentProducts
            .filter(product => !product.quote)
            .filter(product => product.id !== productId);

        setCanSubmit(invalidFulfillmentProducts.length === 0);
    };

    //////////////////////////////////
    // Individual Line Item Listing //
    //////////////////////////////////

    // feature flag //
    const isIndividualLineItemListingEnabled = useFeatureFlag<boolean>('enableIndividualLineItemListing', false);

    // state management for the toggle //
    const [_isIndividualLineItemListingActive, setIsIndividualLineItemListingActive] = useState(false);
    const isIndividualLineItemListingActive = _isIndividualLineItemListingActive && isIndividualLineItemListingEnabled;
    const toggleIndividualLineItemListing = () => {
        setIsIndividualLineItemListingDirty(false);
        setIsIndividualLineItemListingActive(v => !v);
    };

    // dirty state management //
    const [isIndividualLineItemListingDirty, setIsIndividualLineItemListingDirty] = useState(false);
    const [isProductListingDirtyStack, setIsProductListingDirtyStack] = useState<{ id: string; isDirty: boolean }[]>(
        []
    );
    const isProductListingDirty = useLatest(isProductListingDirtyStack.some(({ isDirty }) => isDirty));
    const upsertIsProductListingDirtyStack = (id: string, isDirty: boolean) => {
        setIsProductListingDirtyStack(stack => {
            const index = stack.findIndex(item => item.id === id);
            if (index === -1) {
                return [...stack, { id, isDirty }];
            }
            stack[index].isDirty = isDirty;
            return [...stack];
        });
    };

    // reset forms on toggle //
    const resetEmitter = useEmitter();
    useEffect(() => {
        resetEmitter.emit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIndividualLineItemListingActive]);

    // modal state //
    const [individualLineItemListingModal, setIndividualLineItemListingModal] = useState<
        { isOpen: false; partToBeCleared?: undefined } | { isOpen: true; partToBeCleared: 'lineItems' | 'products' }
    >({ isOpen: false });
    const checkDirtyAndToggleIndividualLineItemListing = async (shouldSwitchToLineItemListing: boolean) => {
        // wait for the next event loop, since some fields only send updates on blur
        // and we need to make sure they are updated before checking if they are dirty
        // see e.g. https://react-spectrum.adobe.com/react-spectrum/NumberField.html#events
        await asyncDelay(0);

        if (isIndividualLineItemListingDirty || isProductListingDirty.current)
            return setIndividualLineItemListingModal({
                isOpen: true,
                partToBeCleared: shouldSwitchToLineItemListing ? 'products' : 'lineItems',
            });

        toggleIndividualLineItemListing();
    };

    // modal content //
    const renderModalContent = ({
        heading,
        content,
        declineButtonLabel,
        confirmButtonLabel,
        onConfirm,
        isLoading,
    }: {
        heading: string;
        content: string;
        declineButtonLabel: string;
        confirmButtonLabel: string;
        onConfirm: (close: () => void) => MaybePromise<unknown>;
        isLoading?: boolean;
    }) => (
        <Modal>
            {({ close }) => (
                <>
                    <Modal.Heading onAbort={close}>{heading}</Modal.Heading>
                    <Modal.Content>{content}</Modal.Content>
                    <Modal.Actions>
                        <Button onPress={close} prominence="secondary">
                            {declineButtonLabel}
                        </Button>
                        <Button
                            isLoading={isLoading}
                            onPress={async () => {
                                await onConfirm(close);
                            }}
                        >
                            {confirmButtonLabel}
                        </Button>
                    </Modal.Actions>
                </>
            )}
        </Modal>
    );

    if (!orderFulfillmentProducts || !orderingOrganization) {
        return null;
    }

    return (
        <>
            <div className="rounded bg-white [counter-reset:productCounter]">
                <div className="border-b p-4">
                    <Header
                        name={productGroup.name ?? ''}
                        templateId={productGroup.templateId}
                        groupType={productGroup.type}
                    />
                </div>
                <div className="divide-y">
                    {orderFulfillmentProducts.map(product => (
                        <FulfillmentCustomRequestProductForOrderedProduct
                            key={product.id}
                            product={product}
                            orderingOrganization={orderingOrganization}
                            hint={getHintByProduct(product)}
                            groupType={productGroup.type}
                            disabled={disabled}
                            disableAmount={isIndividualLineItemListingActive}
                            disableServiceDate={isIndividualLineItemListingActive}
                            overrideFormErrors={getFrancoOverrideFormErrors(product.category)}
                            positionProductId={product.positionProductId}
                            onSalesPriceChange={salesPrice =>
                                handleFrancoSalesPriceChange(salesPrice, product.category)
                            }
                            productConfiguration={product.fulfillmentProductConfiguration}
                            onUpdateProductConfiguration={productConfiguration =>
                                handleUpdateFulfillmentProductConfiguration(product.id, productConfiguration)
                            }
                            onIsDirtyChange={isDirty => upsertIsProductListingDirtyStack(product.id, isDirty)}
                            resetEmitter={resetEmitter}
                        />
                    ))}

                    {fulfillmentProducts.map(product => (
                        <FulfillmentCustomRequestProduct
                            key={product.id}
                            product={{
                                ...product,
                                orderProductGroupId: productGroup.id,
                                positionProductId: null,
                                index: 0,
                                productConfiguration: product.fulfillmentProductConfiguration,
                            }}
                            quote={
                                product.quote && {
                                    ...product.quote,
                                    purchasePrice: product.quote.purchasePrice.toNumber(),
                                    salesPrice: product.quote.salesPrice.toNumber(),
                                }
                            }
                            templateId={productGroup.templateId}
                            orderId={productGroup.orderId}
                            showValidation={true}
                            enableDelete={!disabled}
                            disabled={disabled}
                            showProductPriceNotAddedHint={productGroup.type === PRODUCT_GROUP_TYPES.FRANCO}
                            onCompleteChange={(productId, value) => {
                                setSectionsValidity(getProductSectionName(productId), value);

                                void queryClient.invalidateQueries({
                                    queryKey: ['payment-terms-fulfillment', productGroup.orderId],
                                });
                            }}
                            onQuoteChange={() => {
                                void queryClient.invalidateQueries({
                                    queryKey: ['quotes-by-order', productGroup.orderId],
                                });
                                void queryClient.invalidateQueries({
                                    queryKey: ['fulfillment-summary', productGroup.orderId],
                                });
                            }}
                            onDeleteProduct={() => {
                                setDeleteModal({
                                    open: true,
                                    quoteId: product.quote?.id,
                                    productId: product.id,
                                });
                            }}
                            onUpdateProductConfiguration={fulfillmentProductConfiguration =>
                                handleUpdateFulfillmentProductConfiguration(product.id, fulfillmentProductConfiguration)
                            }
                            onIsDirtyChange={isDirty => upsertIsProductListingDirtyStack(product.id, isDirty)}
                            disableAmount={isIndividualLineItemListingActive}
                            disableServiceDate={isIndividualLineItemListingActive}
                            resetEmitter={resetEmitter}
                        />
                    ))}
                </div>

                {!disabled && (
                    <div className="flex w-full items-center border-t">
                        <AddProductButton
                            templateId={productGroup.templateId}
                            onAddProduct={async product =>
                                await mutateAsyncPostFulfillmentProduct({
                                    productCategory: product.category,
                                    productType: product.type,
                                    productGroupId: productGroup.id,
                                    index: orderFulfillmentProducts.length + fulfillmentProducts.length + 1,
                                })
                            }
                        />
                        {isIndividualLineItemListingEnabled && (
                            <div className="h-[52px] p-4">
                                <ToggleSwitch
                                    label={t('sections.productGroupSelection.individualLineItemListing.toggleLabel')}
                                    size="sm"
                                    checked={isIndividualLineItemListingActive}
                                    onChange={shouldSwitchToLineItemListing => {
                                        void checkDirtyAndToggleIndividualLineItemListing(
                                            shouldSwitchToLineItemListing
                                        );
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            {isIndividualLineItemListingActive && (
                <ProductSelectionIndividualLineItemListing onIsDirtyChange={setIsIndividualLineItemListingDirty} />
            )}
            <ModalTrigger
                isOpen={individualLineItemListingModal.isOpen}
                onOpenChange={isOpen => !isOpen && setIndividualLineItemListingModal({ isOpen })}
            >
                {individualLineItemListingModal.partToBeCleared === 'lineItems' &&
                    renderModalContent({
                        heading: t(
                            'sections.productGroupSelection.individualLineItemListing.resetLineItemsModal.heading'
                        ),
                        content: t(
                            'sections.productGroupSelection.individualLineItemListing.resetLineItemsModal.content'
                        ),
                        declineButtonLabel: t(
                            'sections.productGroupSelection.individualLineItemListing.resetLineItemsModal.declineButton'
                        ),
                        confirmButtonLabel: t(
                            'sections.productGroupSelection.individualLineItemListing.resetLineItemsModal.confirmButton'
                        ),
                        onConfirm: close => {
                            toggleIndividualLineItemListing();
                            close();
                        },
                    })}
                {individualLineItemListingModal.partToBeCleared === 'products' &&
                    renderModalContent({
                        heading: t(
                            'sections.productGroupSelection.individualLineItemListing.resetProductsModal.heading'
                        ),
                        content: t(
                            'sections.productGroupSelection.individualLineItemListing.resetProductsModal.content'
                        ),
                        declineButtonLabel: t(
                            'sections.productGroupSelection.individualLineItemListing.resetProductsModal.declineButton'
                        ),
                        confirmButtonLabel: t(
                            'sections.productGroupSelection.individualLineItemListing.resetProductsModal.confirmButton'
                        ),
                        onConfirm: close => {
                            toggleIndividualLineItemListing();
                            close();
                        },
                    })}
            </ModalTrigger>
            <ModalTrigger isOpen={deleteModal.open} onOpenChange={open => setDeleteModal({ open })}>
                {renderModalContent({
                    heading: t('sections.productGroupSelection.askProductDeletion'),
                    content: t('sections.productGroupSelection.productDeletionHint'),
                    declineButtonLabel: t('sections.productGroupSelection.cancelProductDeletion'),
                    confirmButtonLabel: t('sections.productGroupSelection.confirmProductDeletion'),
                    isLoading: deleteFulfillmentProductIsPending || deleteFulfillmentQuoteIsPending,
                    onConfirm: async () => {
                        if (!deleteModal.open) return;

                        if (deleteModal.quoteId) {
                            await mutateAsyncDeleteFulfillmentQuote({
                                quoteId: deleteModal.quoteId,
                            });
                        }

                        if (deleteModal.productId) {
                            await handleDeleteProduct(deleteModal.productId);
                            setSectionsValidity(getProductSectionName(deleteModal.productId), true);
                        }

                        if (deleteModal.index !== undefined) {
                            setDeleteModal({ open: false });
                        }
                    },
                })}
            </ModalTrigger>
        </>
    );
};

interface HeaderProps {
    name: string;
    templateId: string;
    groupType: PRODUCT_GROUP_TYPE;
}

const Header: React.FC<HeaderProps> = ({ name, templateId, groupType }) => {
    const { products } = useFulfillmentContext();

    return (
        <ProductGroupHeader
            products={products.map(product => ({
                category: product.category,
                type: product.type,
                templateId: product.templateId ?? null,
                createdAt: new Date(),
                updatedAt: new Date(),
                quote: {
                    templateId: product.templateId,
                    category: product.category,
                    amount: product.quote?.amount,
                    currencyCode: product.quote?.currencyCode,
                    salesPrice: product.quote?.salesPrice.toNumber(),
                    purchasePrice: product.quote?.purchasePrice.toNumber(),
                    unit: product.quote?.unit,
                },
            }))}
            productGroupTemplateId={templateId}
            productGroupType={groupType}
            productGroupName={name}
            productGroupNameDisabled={true}
        />
    );
};
