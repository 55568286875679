import {
    GetPositionQuoteResponseSchema,
    PatchPositionQuoteRequestSchema,
    PatchPositionQuoteResponseSchema,
    PostPositionQuoteRequestSchema,
} from '@schuettflix/interfaces';
import { getCustomRequestClient } from '@/clients/httpClients.ts';
import { z } from 'zod';

export const createPositionQuote = async ({
    positionQuote,
}: {
    positionQuote: z.infer<typeof PostPositionQuoteRequestSchema>;
}) => {
    return getCustomRequestClient()
        .post<z.infer<typeof PostPositionQuoteRequestSchema>>(`/v1/position-product`, positionQuote)
        .then(res => GetPositionQuoteResponseSchema.parse(res.data));
};

export const getPositionQuoteByPositionProductId = async ({
    positionProductId,
}: {
    positionProductId: z.infer<typeof PostPositionQuoteRequestSchema>['positionProductId'];
}) => {
    return getCustomRequestClient()
        .get<z.infer<typeof GetPositionQuoteResponseSchema>>(`/v1/position-product/${positionProductId}`)
        .then(res => GetPositionQuoteResponseSchema.parse(res.data));
};

export const patchPositionQuoteByPositionProductId = async ({
    positionProductId,
    positionQuote,
}: {
    positionProductId: z.infer<typeof PostPositionQuoteRequestSchema>['positionProductId'];
    positionQuote: z.infer<typeof PatchPositionQuoteRequestSchema>;
}) => {
    return getCustomRequestClient()
        .patch<void>(`/v1/position-product/${positionProductId}`, positionQuote)
        .then(res => PatchPositionQuoteResponseSchema.parse(res.data));
};
