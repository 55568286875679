import z from 'zod';
import { PRODUCT_CATEGORY_SCHEMA } from '../order-product/product-category.ts';
import { PaymentSettingsWithOptionsResponseSchema } from './payment-settings.ts';

export const PostProcessedPaymentTermsUrlSchema = z.strictObject({
    referenceId: z.string().uuid(),
});

export const ProcessedPaymentTermsPartialLineItemSchema = z.object({
    orderingOrganizationId: z.number(),
    creditorOrganizationId: z.number(),
    debtorOrganizationId: z.number(),
    sflxPlatformId: z.number(),
    productCategory: PRODUCT_CATEGORY_SCHEMA,
    productId: z.string().uuid(),
});

export type ProcessedPaymentTermsPartialLineItem = z.infer<typeof ProcessedPaymentTermsPartialLineItemSchema>;

export const ProcessedPaymentTermsRequestSchema = z.object({
    lineItems: z.array(ProcessedPaymentTermsPartialLineItemSchema),
});

export const PostProcessedPaymentTermsResponseSchema = z.strictObject({
    paymentTerms: z.array(
        z.strictObject({
            referenceId: z.string(),
            organizationId: z.number(),
            paymentSettings: z.array(PaymentSettingsWithOptionsResponseSchema),
        })
    ),
});

export type PostProcessedPaymentTermsResponse = z.infer<typeof PostProcessedPaymentTermsResponseSchema>;
