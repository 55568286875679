import { LINE_ITEM_CATEGORY_SCHEMA, LINE_ITEM_TYPE_SCHEMA } from '../events/item-fulfillment-event-payload';
import { BILLING_INTERVAL_SCHEMA } from '../finance-settings/billing-interval';
import { BILLING_TYPE_SCHEMA } from '../finance-settings/billing-types';
import { z } from 'zod';

const PaymentTermsLineItemBaseSchema = z.object({
    lineItemCategory: LINE_ITEM_CATEGORY_SCHEMA,
    debtorOrganizationId: z.number().int().optional(),
    creditorOrganizationId: z.number().int().optional(),
    type: LINE_ITEM_TYPE_SCHEMA,
    hasProjectPosition: z.boolean().default(false),
});

//TODO: remove nullish() when this mar-3072 is implemented
const PaymentTermsLineItemBillingSchema = z.discriminatedUnion('billingMethod', [
    z.object({
        billingMethod: z.literal(BILLING_TYPE_SCHEMA.Values.PARTNER_INVOICE),
        paymentTermId: z.undefined(),
        billingInterval: BILLING_INTERVAL_SCHEMA.nullish(),
    }),
    z.object({
        billingMethod: z.literal(BILLING_TYPE_SCHEMA.Values.INVOICE),
        paymentTermId: z.number().int(),
        billingInterval: BILLING_INTERVAL_SCHEMA.nullish(),
    }),
    z.object({
        billingMethod: z.literal(BILLING_TYPE_SCHEMA.Values.CREDIT_NOTE),
        paymentTermId: z.number().int(),
        billingInterval: BILLING_INTERVAL_SCHEMA.nullish(),
    }),
]);

const PaymentTermsLineItemSchema = z.intersection(PaymentTermsLineItemBaseSchema, PaymentTermsLineItemBillingSchema);

export const GetPaymentTermsResponseSchema = z.object({
    paymentTermsLineItems: z.array(PaymentTermsLineItemSchema),
});

export const GetPaymentTermsByOrderRequestSchema = z.object({
    orderId: z.string().uuid(),
});

export type GetPaymentTermsResponse = z.infer<typeof GetPaymentTermsResponseSchema>;
