import z from 'zod';

const PostDocumentZipOrderItemSchema = z.object({
    id: z.string(),
});
const PostDocumentZipFinanceItemSchema = z.object({
    documentNumber: z.string(),
});

export const PostDocumentZipBodySchema = z.object({
    finance: z.array(PostDocumentZipFinanceItemSchema),
    order: z.array(PostDocumentZipOrderItemSchema),
});
export type PostDocumentZipBody = z.infer<typeof PostDocumentZipBodySchema>;

export type PostDocumentZip = z.infer<typeof PostDocumentZipBodySchema>;

export type PostDocumentZipOrderItem = z.infer<typeof PostDocumentZipOrderItemSchema>;

export type PostDocumentZipFinanceItem = z.infer<typeof PostDocumentZipFinanceItemSchema>;

export const PostDocumentZipResponseSchema = z.object({
    url: z.string(),
});
export type PostDocumentZipResponse = z.infer<typeof PostDocumentZipResponseSchema>;
