import { z } from 'zod';
import { GetPositionResponseSchema } from './get-position.ts';
import { PRODUCT_CATEGORY_SCHEMA } from '../order-product/product-category.ts';
import { OrganizationSchema } from '../organization/organization.ts';

export const GetPositionDetailsUrlSchema = z.object({
    positionId: z.string().uuid(),
});

export const GetPositionDetailsResponseSchema = z.strictObject({
    position: GetPositionResponseSchema,
    organizations: z.array(
        OrganizationSchema.pick({ id: true, name: true, billingAddress: true }).merge(
            z.strictObject({
                productCategories: z.array(PRODUCT_CATEGORY_SCHEMA),
            })
        )
    ),
});
